import React from 'react';
import {Col} from 'react-bootstrap';
import Layout from '../../components/layout';
import Section from '../../components/section';
import Subscribe from '../../components/subscribe';

import email from '../../images/why-hexospark/email.png';
import crm from '../../images/why-hexospark/crm.png';
import ready from '../../images/why-hexospark/ready.png';

import newWay from '../../images/why-hexospark/new-way.png';
import './styles.scss';
import Button from '../../components/form/button';

const WhyHexospark = () => {
  const handleClick = () => {
    window.location.href = 'https://dash.hexospark.com';
  };
  return (
    <Layout>
      <div className="why-hexospark-wrapper">
        <Section light whySpark pageheroPading lgPd>
          <Col lg={6} className="align-self-center">
            <h1 className="text-mobile">
              Cold email outreach is crazy effective with a <span className="header-span">3800% ROI</span>
            </h1>
            <img src={email} className="d-lg-none my-2 mt-3 section-image img-mobile" />
            <div>
              <p className="p-pagehero">
                Sales used to be hard… But today, you can reach just about anyone in the world via email.
              </p>
              <p className="p-pagehero">No suits, in-person visits, or cold calls required.</p>
              <p className="p-pagehero">
                In fact, email outreach is one of the most cost-effective and scalable strategies you can use to
                supercharge your networking, get more clients and grow your sales.
              </p>
            </div>
          </Col>
          <Col lg={6} className="d-none d-lg-flex">
            <img src={email} className="section-image ml-0" />
          </Col>
        </Section>
        <Section lightGrey>
          <Col lg={6} className="d-none d-lg-flex">
            <img src={crm} className="section-image" />
          </Col>
          <Col lg={6} className="align-self-center">
            <span className="text-mobile">THE OLD WAY</span>
            <h2 className="text-mobile">But here is what they don’t tell you…</h2>
            <img src={crm} className="d-lg-none my-2 img-mobile section-image" />

            <p className="old-description">
              Cold email outreach works really well, but from our experience, there are three fundamental problems:
            </p>

            <div className="d-flex mb_20">
              <div className="order-number-circle">
                <span className="old-way-number">1</span>
              </div>
              <div>
                <p className="list-item">
                  Even with personalization variables, bulk emailing is no longer as effective, requiring sending
                  thousands of emails to compensate for low reply rates.
                </p>
              </div>
            </div>
            <div className="d-flex mb_20">
              <div className="order-number-circle">
                <span className="old-way-number">2</span>
              </div>
              <div>
                <p className="list-item">
                  1-1 Emailing with highly personalized messages is super effective but writing these takes forever.
                </p>
              </div>
            </div>
            <div className="d-flex mb_20">
              <div className="order-number-circle">
                <span className="old-way-number">3</span>
              </div>
              <div>
                <p className="list-item">
                  The third problem is complexity… And the need to juggle spreadsheets, email accounts, and tons of
                  different tools to keep your pipeline full.
                </p>
              </div>
            </div>
          </Col>
        </Section>
        <Section>
          <Col lg={6} className="align-self-center">
            <span className="text-mobile">THE NEW WAY</span>
            <h2 className="text-mobile">There has to be a better way, right? </h2>
            <img src={newWay} className="d-lg-none my-2 img-mobile section-image" />

            <p className="what-if">
              What if you could have <b> ONE simple platform?</b>
            </p>
            <p className="p-pagehero">Featuring...</p>
            <ul>
              <li>An easy-to-use CRM to manage and nurture your prospects from lead to client.</li>
              <li>
                A browser extension that suggests 1-1 personalized icebreakers for prospects you find on LinkedIn,
                automatically adding these into your CRM and email outreach campaigns.
              </li>
              <li>
                The ability to create personalized email outreach campaigns at scale with intelligent follow-ups based
                on how prospects interact with your emails.
              </li>
              <li>
                And a unified inbox so you and your team can manage all your email replies from all your accounts right
                inside Hexospark. No more logging into multiple email accounts 🚀.
              </li>
            </ul>
          </Col>
          <Col lg={6} className="d-none d-lg-flex">
            <img src={newWay} className="section-image" />
          </Col>
        </Section>
        <Section lightGrey>
          <Col lg={6} className="d-none d-lg-flex">
            <img src={ready} className="section-image" />
          </Col>
          <Col lg={6} className="align-self-center">
            <h2 className="text-mobile">Ready to 10X your sales?</h2>
            <img src={ready} className="d-lg-none my-2 img-mobile section-image" />

            <p className="old-description">
              Break the ice, personalize emails and automate follow-ups to turn leads into clients. Hexospark is
              changing the email outreach game, empowering you with…{' '}
            </p>

            <div className="d-flex mb-0">
              <ul className="mb-0 pt-0 pb-0">
                <li>A personalized approach to email</li>
                <li>Manage unlimited contacts in your CRM</li>
                <li>Send unlimited emails and follow-ups*</li>
                <li>Capture unlimited leads from LinkedIn </li>
                <li>Easy access to all your replies to keep your pipeline flowing </li>
              </ul>
            </div>

            <Button btnText={'Get started today'} onClick={handleClick} className="get-started-buttom" />
            <p className="mt-3 based"> * based on your own email account limits.</p>
          </Col>
        </Section>
        {/* <Section dark className="">
          <div className="row px-0 mx-auto subscribe-section">
            <div className="col-lg-12 text-center">
              <h3 className="notified">Want to be notified as soon as we launch?</h3>
              <p className="pb_20">
                Sign up to our notification list to be the first to know when Hexospark launches later this year.
              </p>
              <Subscribe btnText="LET ME KNOW" />
            </div>
          </div>
        </Section> */}
      </div>
    </Layout>
  );
};

export default WhyHexospark;
